*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    overflow: hidden;
    font-size: 20px;
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
    cursor: pointer;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 5rem;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}

.settingsBar {
    width: 70vh;
    height: 3rem;
    border: 2px solid white;
    border-radius: 1.5rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    padding: 0.125rem;
    background-color: white;
    box-shadow: 0 0 10px black;
    z-index: 20;
}

.detailsDiv {
    width: 33%;
    height: 2.5rem;
    border-radius: 1.25rem 0 0 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.detailsDiv, .uNoiseSeedDiv, .otherDiv {
    width: 33%;
    height: 2.5rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
}

.slidecontainer {
    width: 50%; /* Width of the outside container */
    pointer-events: auto;
}

/* The slider itself */
.slider {
    -webkit-appearance: none;  /* Override default CSS styles */
    appearance: none;
    width: 100%; /* Full-width */
    height: 10px; /* Specified height */
    background: #d3d3d3; /* Grey background */
    outline: none; /* Remove outline */
    opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
    -webkit-transition: .2s; /* 0.2 seconds transition on hover */
    transition: opacity .2s;
    border-radius: 5px;
    transform: translateY(-1vw) translateX(2vw);
}

/* Mouse-over effects */
.slider:hover {
    opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 25px; /* Set a specific slider handle width */
    height: 25px; /* Slider handle height */
    background: white; /* Green background */
    cursor: pointer; /* Cursor on hover */
    border-radius: 50%;
    box-shadow: 0 0 5px black;
}

.slider::-moz-range-thumb {
    width: 25px; /* Set a specific slider handle width */
    height: 25px; /* Slider handle height */
    background: white; /* Green background */
    cursor: pointer; /* Cursor on hover */
}

.labelText {
    height: 100%;
    width: 80%;
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    font-family: 'Bebas Neue', cursive;
    font-size: 1.5vw;
}

.otherLabelText {
    height: 100%;
    width: 100%;
    display: flex;
    box-sizing: border-box;
    justify-content: space-evenly;
    align-items: center;
    font-family: 'Bebas Neue', cursive;
    font-size: 1.5vw;
}

.sliderPopupDiv {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 5rem;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}

.slideContainerBar {
    width: 70vh;
    height: 70vh;
    border-radius: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 0.125rem;
    transform: rotate(-90deg);
    flex-direction: column;
    pointer-events: none;
}

.sliderDiv {
    height: 33%;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.arrowBox {
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid black;
    border-right: 1px solid black;
    cursor: pointer;
}

.toggleBox {
    width: 1rem;
    height: 1rem;
    border: 2px solid black;
    border-radius: 0.25rem;
    pointer-events: auto;
    cursor: pointer;
    margin-left: 0.5rem;
}

.halfDivs {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.material-symbols-outlined {
    font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48
}

#detailsSliderDiv, #seedSliderDiv {
    transform: translateX(-500px);
    opacity: 0;
}

.flipped {
    transform: scaleY(-1);
}
